.Arrows {
  align-self: center;
  bottom: 2rem;
}

.Arrows div, .Arrows a {
  color: #999;
  font-size: 4rem;
  display: inline-block;
  padding: 0 0.5rem;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.Arrows a {
  text-decoration: none;
}

.hide {
  color: rgba(0, 0, 0, 0) !important;
  cursor: default;
}