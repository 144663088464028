.ContentRight {
  display: grid;
  grid-column: 2;
  grid-row: 1;
  grid-template-columns: 50% 50%;
  grid-template-rows: 25% 25% 25% 25%;
  height: 99%;
  justify-items: center;
  width: 100%;
}

.ContentRight a, .ContentRight img {
  width: 100%;
  height: 100%;
}

.ContentRight img {
  object-fit: cover;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.ContentRight img:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  transition: 0.5s ease-in-out;
}

.ImagesLeft {
  grid-column: 1;
  padding-right: 0.25rem;
}

.ImagesRight {
  grid-column: 2;
  padding-left: 0.25rem;
}

.Right3 {
  object-fit: contain !important;
  background-repeat: no-repeat;
}

.Right4 {
  background-color: gray;
  height: 100%;
  width: 100%;
}