.RightColumn {
  grid-column: 2;
  height: 100%;
  text-align: center;
  width: 100%;
}

.Portrait {
  max-width: 100%;
  max-height: 99.5%;
}