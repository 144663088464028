.Content {
  max-height: 100vh;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5rem 0;
}

.Text {
  display: flex;
  flex-direction: column;
  padding: 0 5rem;
}

.Text h2 {
  font-size: 2rem;
}

.Text span {
  font-weight: bold;
}

.Text p {
  font-size: 0.9rem;
}

.alt {
  background-color: black;
  color: white;
}

.alt p {
  font-size: 1rem !important;
}

.white a {
  color: white;
}