.Container {
  grid-column: 1 / 3;
  max-height: 100vh;
  display: flex;
  padding-left: 15%;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48%;
}

.Logo {
  width: 100%;
}

.Links {
  align-self: flex-end;
}

.Links a {
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: 1rem;
}

.Links a:hover {
  text-decoration: underline;
}

.Links a:visited {
  color: #000;
}