.App {
  background-color: #fff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns: 33% 67%;
  grid-template-rows: 100vh;
}
