.ContactForm {
  grid-column: 1;
}

.ContactForm fieldset {
  border: none;
  padding: 0;
  margin: 0.5rem 0;
}

.ContactForm label {
  display: block;
  font-size: 1.5rem;
  text-align: left;
}

.ContactForm input, .ContactForm textarea {
  border: 1px solid black;
  border-radius: 5px;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

.ContactForm textarea {
  height: 13rem;
  line-height: 1.5rem;
}

.ContactForm input[type="submit"] {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(199, 35, 44, 1);
  cursor: pointer;
  font-size: 2rem;
  margin-top: 1rem;
  padding: 0;
  width: auto;
}

.ContactForm input[type="submit"]:disabled {
  color: rgba(199, 35, 44, 0.6);
  cursor: default;
}

.ContactForm p {
  display: inline;
  margin-left: 2rem;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.SuccessMessage {
  color: green;
}

.FailMessage {
  color: red;
}