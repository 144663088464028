.RightIMG {
  align-self: center;
  grid-column: 2;
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.Contain, .Contain img {
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}

.Cover, .Cover img {
  height: 100%;
  width: 100%;
  object-fit: cover !important;
}

.TwoByTwo {
  column-gap: 1%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  height: 100%;
  row-gap: 1%;
  width: 100%;
}

.ThreeByTwo {
  column-gap: 1%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 50% 50%;
  height: 100%;
  row-gap: 1%;
  width: 100%;
}

.TwoRows {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50% 50%;
  height: 100%;
  row-gap: 2%;
  width: 100%;
}

.TwoCols {
  column-gap: 2%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
}

.ThreeCols {
  column-gap: 1%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
}