.DCE5Top, .DCE5Bottom {
  display: grid;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
}

.DCE5Top {
  grid-row: 1;
  grid-template-columns: 50% 25% 25%;
}

.DCE5Bottom {
  grid-row: 2;
  grid-template-columns: 33% 33% 33%;
}